.section-model {
	// background-color: $color-grey-light-1;
	background-image: linear-gradient(to right bottom, $color-primary-light, $color-primary-dark);
	padding: 50px 0 150px 0;
	// margin-top: -115px;

	@include respond(tab-port) {
			padding: 200px 0 100px 0;
	}
}

.feature-model {
	background-color: rgba(#fff, .8);
	font-size: 16px;
	padding: 25px;
	text-align: center;
	border-radius: 3px;
	box-shadow: 0 15px 40px rgba(#000, .15);
	transition: transform .3s;

	@include respond(tab-port) {
			padding: 20px;
	}

	&__icon {
			font-size: 60px;
			margin-bottom: 5px;
			display: inline-block;
			background-image: linear-gradient(to right, $color-primary-light, $color-primary-dark);
			-webkit-background-clip: text;
			color: transparent;

			@include respond(tab-port) {
					margin-bottom: 0;
			}
	}

	&:hover {
			transform: translateY(-15px) scale(1.03);
	}
}

.row {
  max-width: $grid-width;
  // background-color: #eee;
  margin: 0 auto;

  &:not(:last-child) {  // 괄호안의 것만 제외하고 모두 적용
    margin-bottom: $gutter-vertical;

    @include respond(tab-port) {
      margin-bottom: $gutter-vertical-small;
    }
  }

  @include respond(tab-port) {
    max-width: 50rem;
    padding: 0 3rem;
  }

  @include clearfix;

  // * 포함하는 모든,  $ 로 끝나는, ^ 로 시작하는
  [class^="col-"] {
    // background-color: orangered;
    float: left;
    // color: white;

    &:not(:last-child){
      margin-right: $gutter-horizontal;

      @include respond(tab-port) {
        margin-right: 0;
        margin-bottom: $gutter-vertical-small;
      }
    }

    @include respond(tab-port) {
      width: 100% !important;
    }

  }

  .col-1-of-2 {
    width: calc((100% - #{$gutter-horizontal}) / 2);
  }

  .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal}) / 3);
  }

  .col-2-of-3 {
    width: calc( 2 * ((100% - 2 * #{$gutter-horizontal}) / 3) + #{$gutter-horizontal});
  }

  .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal}) / 4);
  }

  .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * #{$gutter-horizontal}) / 4) + #{$gutter-horizontal});
  }

  .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * #{$gutter-horizontal}) / 4) + 2 * #{$gutter-horizontal});
  }
}
