// COLORS
$color-primary: #55c57a;
$color-primary-light: #7ed56f;
$color-primary-dark: #28b485;

$color-secondary-light: #ffb900;
$color-secondary-dark: #ff7730;

$color-tertiary-light: #2998ff;
$color-tertiary-dark: #5643fa;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;

$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #000;

// FONT
$default-font-size: 16px;



//GRID
$grid-width: 1140px;
$gutter-vertical: 80px;
$gutter-vertical-small: 60px;
$gutter-horizontal: 60px;

.u-center-text { text-align: center !important; }

.u-margin-bottom-small { margin-bottom: 15px !important; }
.u-margin-bottom-medium {
    margin-bottom: 40px !important;

    @include respond(tab-port) {
        margin-bottom: 30px !important;
    }
}

.u-margin-bottom-big { 
    margin-bottom: 80px !important;

    @include respond(tab-port) {
        margin-bottom: 50px !important;
    }
}

.u-margin-top-big { margin-top: 80px !important; }
.u-margin-top-huge { margin-top: 100px !important; }


.section-blogs {
	// background-color: $color-grey-light-1;
	// background-image:linear-gradient(to right bottom, rgba(#8F92A6, 0.8), rgba(#464B59, 0.8)), url(../../../images/nat-7.jpg);
	background-image: url(../../../images/2020_wallpaper.jpg);
	background-size: cover;
	background-position: center;
	overflow: hidden;

	padding: 20px 0 100px 0;
	// margin-top: -115px;

	@include respond(tab-port) {
			padding: 200px 0 100px 0;
	}
}

.card {
	//FUNCTIONALITY
	perspective: 1500px;
	-moz-perspective: 1500px;
	position: relative;
	height: 520px;

	&__side {
			height: 520px;
			transition: all .8s ease;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			backface-visibility: hidden;
			border-radius: 3px;
			overflow: hidden;  // 이미지가 부모를 가릴때 부모 옵션이 적용될 수 있게 함
			box-shadow: 0 15px 40px rgba($color-black, .15);


			&--front {
					background-color: $color-white;
			}
	
			&--back {
					transform: rotateY(180deg);

					&-1 {
							background-image: linear-gradient(to right bottom, $color-secondary-light, $color-secondary-dark)
					}

					&-2 {
							background-image: linear-gradient(to right bottom, $color-primary-light, $color-primary-dark)
					}

					&-3 {
							background-image: linear-gradient(to right bottom, $color-tertiary-light, $color-tertiary-dark)
					}
			}
	}



	&:hover &__side--front {
			transform: rotateY(-180deg);
	}

	&:hover &__side--back {
			transform: rotateY(0);
	}

	// FRONT SIDE STYLING
	&__picture {
			background-size: cover;
			height: 230px;
			background-blend-mode: screen;
			-webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
			clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
			border-top-left-radius: 3px;
			border-top-right-radius: 3px;
			

			&--1 {
					background-image: linear-gradient(to right bottom, $color-secondary-light, $color-secondary-dark), url(../../../images/tamper.jpg);
			}

			&--2 {
					background-image: linear-gradient(to right bottom, $color-primary-light, $color-primary-dark), url(../../../images/cheat.jpg);
			}

			&--3 {
					background-image: linear-gradient(to right bottom, $color-tertiary-light, $color-tertiary-dark), url(../../../images/cyberess.jpg);
			}
	}

	&__heading {
			font-size: 28px;
			font-weight: 300;
			text-transform: uppercase;
			text-align: right;
			color: $color-white;
			position: absolute;
			top: 120px;
			right: 20px;
			width: 75%;
	}

	&__heading-span {
			padding: 10px 15px;
			-webkit-box-decoration-break: clone;
			box-decoration-break: clone;
			&--1 {
					background-image: linear-gradient(to right bottom, rgba($color-secondary-light, .85), rgba($color-secondary-dark, .85));
			}

			&--2 {
					background-image: linear-gradient(to right bottom, rgba($color-primary-light, .85), rgba($color-primary-dark, .85));
			}

			&--3 {
					background-image: linear-gradient(to right bottom, rgba($color-tertiary-light, .85), rgba($color-tertiary-dark, .85));
			}
	}

	&__datails {
			padding: 30px;

			ul {
					list-style: none;
					width: 80%;
					margin: 0 auto;

					li{ 
							text-align: center;
							font-size: 15px;
							padding: 10px;

							&:not(:last-child) {
									border-bottom: 1px solid $color-grey-light-2;
							}
					}
			}
	}

	//FRONT SIDE STYLING
	&__cta {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 90%;
			text-align: center;
	}

	&__price-box {
			text-align: center;
			color: $color-white;
			margin-bottom: 80px;
	}

	&__price-only {
			font-size: 14px;
			text-transform: uppercase;
	}

	&__price-value {
			font-size: 30px;
			font-weight: 100;
	}

//@include respond(tab-port) {
	@media only screen and (max-width: 900px),
	only screen and (hover: none) {

height: auto;
border-radius: 3px;
background-color: #fff;
box-shadow: 0 15px 40px rgba(#000, .15);

&__side {
	height: auto;
	position: relative;
	box-shadow: none;

	&--back {
			transform: rotateY(0);
			clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
	}
}

&:hover &__side--front {
	transform: rotateY(0);
}


&__details {
	padding: 10px 30px;
}

// FRONT SIDE STYLING
&__cta {
	position: relative;
	top: 0%;
	left: 0;
	transform: translate(0);
	width: 100%;
	padding: 70px 40px 40px 40px;
}

&__price-box {
	margin-bottom: 30px;
}

&__price-value {
	font-size: 30px;
}
}


}

