.features-split {
	// background-image: linear-gradient(to right bottom, #36AE7C, #187498);
	background-image: url(../../../images/2020_wallpaper.jpg);
	margin-top: 3px;

	.section-inner {
		padding-top: $features-split--padding-t__mobile;
		padding-bottom: $features-split--padding-b__mobile;
	}

	.section-header {
		padding-bottom: $features-split-header--padding__mobile;
	}
}

@include media( '>medium' ) {

	.features-split {

		.section-inner {
			padding-top: $features-split--padding-t__desktop;
			padding-bottom: $features-split--padding-b__desktop;
		}

		.section-header {
			padding-bottom: $features-split-header--padding__desktop;
		}
	}
}
