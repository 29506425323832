.hero {

	background-image: 
		url(../../../images/press.gif);
		
	opacity: 1;
	background-size: cover;
	background-position: center;
	overflow: hidden;

	.section-inner {
		padding-top: $hero--padding-t__mobile;
		padding-bottom: $hero--padding-b__mobile;
	}
}

.hero-inner {

	// Affects only hero full
	> .hero-content + .hero-figure,
	> .hero-figure + .hero-content {
		margin-top: $hero--inner-padding-v_mobile;
	}

	> .hero-figure {

		> a {
			display: inline-flex;
			// prevents stretching
			align-items: center;
			vertical-align: top;
		}
	}
}

@include media( '<=medium' ) {

	.hero {

		.split-wrap {

			.split-item {

				.split-item-content {
					margin-bottom: $hero--inner-padding-v_mobile;
				}
			}

			&.invert-mobile {

				.split-item {

					.split-item-image {
						margin-bottom: $hero--inner-padding-v_mobile;
					}
				}
			}
		}
	}
}

@include media( '>medium' ) {

	.hero {

		.section-inner {
			padding-top: $hero--padding-t__desktop;
			padding-bottom: $hero--padding-b__desktop;
		}
	}

	.hero-inner {

		// Affects only hero full
		> .hero-content + .hero-figure,
		> .hero-figure + .hero-content {
			margin-top: $hero--inner-padding-v_desktop;
		}
	}
}

//////////////////////////////////////////////////////////////////////////////////////////////

.hero-v1 {

	background-image: linear-gradient(
		to right bottom,
		rgba(#8F92A6, 0.2), rgba(#464B59, 0.5)),
		url(../../../images/wave.gif);;
	opacity: 1;
	background-size: cover;
	background-position: center;
	overflow: hidden;

	.section-inner {
		padding-top: $hero--padding-t__mobile;
		padding-bottom: $hero--padding-b__mobile;
	}
}

.hero-v1-inner {

	// Affects only hero full
	> .hero-v1-content + .hero-v1-figure,
	> .hero-v1-figure + .hero-v1-content {
		margin-top: $hero--inner-padding-v_mobile;
	}

	> .hero-v1-figure {

		> a {
			display: inline-flex;
			// prevents stretching
			align-items: center;
			vertical-align: top;
		}
	}
}

@include media( '<=medium' ) {

	.hero-v1 {

		.split-wrap {

			.split-item {

				.split-item-content {
					margin-bottom: $hero--inner-padding-v_mobile;
				}
			}

			&.invert-mobile {

				.split-item {

					.split-item-image {
						margin-bottom: $hero--inner-padding-v_mobile;
					}
				}
			}
		}
	}
}

@include media( '>medium' ) {

	.hero-v1 {

		.section-inner {
			padding-top: $hero--padding-t__desktop;
			padding-bottom: $hero--padding-b__desktop;
		}
	}

	.hero-v1-inner {

		// Affects only hero full
		> .hero-v1-content + .hero-v1-figure,
		> .hero-v1-figure + .hero-v1-content {
			margin-top: $hero--inner-padding-v_desktop;
		}
	}
}

//////////////////////////////////////////////////////////////////////////////////////////////

.hero-v2 {

	background-image: 
		// linear-gradient(
		// to right bottom,
		// rgba(#8F92A6, 0.4), rgba(#464B59, 0.7)),
		url(../../../images/mountain.png);
		
	opacity: 1;
	background-size: cover;
	background-position: center;
	overflow: hidden;

	.section-inner {
		padding-top: $hero--padding-t__mobile;
		padding-bottom: $hero--padding-b__mobile;
	}
}

.hero-v2-inner {

	// Affects only hero full
	> .hero-v2-content + .hero-v2-figure,
	> .hero-v2-figure + .hero-v2-content {
		margin-top: $hero--inner-padding-v_mobile;
	}

	> .hero-v2-figure {

		> a {
			display: inline-flex;
			// prevents stretching
			align-items: center;
			vertical-align: top;
		}
	}
}

@include media( '<=medium' ) {

	.hero-v2 {

		.split-wrap {

			.split-item {

				.split-item-content {
					margin-bottom: $hero--inner-padding-v_mobile;
				}
			}

			&.invert-mobile {

				.split-item {

					.split-item-image {
						margin-bottom: $hero--inner-padding-v_mobile;
					}
				}
			}
		}
	}
}

@include media( '>medium' ) {

	.hero-v2 {

		.section-inner {
			padding-top: $hero--padding-t__desktop;
			padding-bottom: $hero--padding-b__desktop;
		}
	}

	.hero-v2-inner {

		// Affects only hero full
		> .hero-v2-content + .hero-v2-figure,
		> .hero-v2-figure + .hero-v2-content {
			margin-top: $hero--inner-padding-v_desktop;
		}
	}
}
