.composition {
    position: relative;

    &__photo {
        width: 55%;
        box-shadow: 0 15px 40px rgba(#000, .4);
        border-radius: 2px;
        // position: absolute;
        z-index: 10;
        transition: all .2s;
        outline-offset: 20px;

        @include respond(tab-port) {
            // float: left;
            // position: relative;
            // width: 33.33333333%;
            box-shadow: 0 15px 30px rgba(#000, .2);
        }

        &--p1 {
            left: 0;
            top: -20px;

            @include respond(tab-port)  {
                top: 0;
                transform: scale(1.2);
            }
        }

        &--p2 {
            right: 0;
            top: 20px;

            @include respond(tab-port)  {
                top: -10px;
                transform: scale(1.3);
                z-index: 100;
            }
        }

        &--p3 {
            left: 20%;
            top: 100px;

            @include respond(tab-port)  {
                top: 10px;
                left: 0;
                transform: scale(1.1);
            }
        }

        &:hover {
            outline: 15px solid #55c57a;
            transform: scale(1.05) translateY(-5px);
            box-shadow: 0 25px 40px rgba(#000, .5);
            z-index: 20;
        }

    }

    &:hover &__photo:not(:hover) {
        transform: scale(.95);
    }
    //composition:hover composition__photo:not(:hover) {
}

.composition-1 {
    position: relative;

    &__photo {
        width: 70%;
        box-shadow: 0 15px 40px rgba(#000, .4);
        border-radius: 2px;
        position: absolute;
        z-index: 10;
        transition: all .2s;
        outline-offset: 20px;

        @include respond(tab-port) {
            float: left;
            position: relative;
            width: 33.33333333%;
            box-shadow: 0 15px 30px rgba(#000, .2);
        }

        &--p1 {
            left: 0;
            top: -20px;

            @include respond(tab-port)  {
                top: 0;
                transform: scale(1.2);
            }
        }

        &--p2 {
            right: 0;
            top: 20px;

            @include respond(tab-port)  {
                top: -10px;
                transform: scale(1.3);
                z-index: 100;
            }
        }

        &--p3 {
            left: 20%;
            top: 100px;

            @include respond(tab-port)  {
                top: 10px;
                left: 0;
                transform: scale(1.1);
            }
        }

        &:hover {
            outline: 15px solid #55c57a;
            transform: scale(1.05) translateY(-5px);
            box-shadow: 0 25px 40px rgba(#000, .5);
            z-index: 20;
        }

    }

    &:hover &__photo:not(:hover) {
        transform: scale(.95);
    }
    //composition:hover composition__photo:not(:hover) {
}


.blogcard {
    transition: all .2s;
        &:hover {
            // outline: 15px solid #55c57a;
            transform: scale(1.05) translateY(-5px);
            box-shadow: 0 25px 40px rgba(#000, .5);
            z-index: 20;
        }

}