// COLORS
.section-loadintro {
	background-color: $color-grey-light-1;
	padding: 20px 0 10px 0;
	// padding: 20px;

	position: relative;

	background-image: linear-gradient(
		to right bottom,
		rgba(#8F92A6, 0.8), rgba(#464B59, 0.8)),
		url(../../../images/lion-1.gif);
	// background-image: url(../../../images/lion-1.gif);
	opacity: .8;
	background-size: cover;
	background-position: center;
	overflow: hidden;

	
	

	@include respond(tab-port) {
			padding: 200px 0 100px 0;
	}
}

.loadintro {
	// width: 75%;
	margin: 0 auto;
	box-shadow: 0 3rem 6rem rgba($color-black, .1);
	background-color: rgba($color-white, .6);
	border-radius: 3px;
	padding: 3rem;
	padding-left: 9rem;
	font-size: $default-font-size;
	transform: skewX(-12deg);

	@include respond(tab-port) {
			width: 100%;
			padding: 4rem;
			padding-left: 7rem;
	}

	@include respond(phone) {
			transform: skewX(0);
	}

	&__shape {
			width: 15rem;
			height: 15rem;
			float: left;
			transform: translateX(-3rem) skewX(12deg);
			position: relative;
			overflow: hidden;
			border-radius: 50%;

			@supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
					-webkit-clip-path: circle(50% at 50% 50%);
					clip-path: circle(50% at 50% 50%);
					-webkit-shape-outside: circle(50% at 50% 50%);
					shape-outside: circle(50% at 50% 50%);
					border-radius: none;
			}

			@include respond(phone) {
					transform: translateX(-3rem) skewX(0);
			}
	}

	&__img {
			height: 100%;
			// transform: translateX(-4rem) scale(1.4);
			backface-visibility: hidden;
			transition: all .5s;
	}

	&__text {
			transform: skewX(12deg);

			@include respond(phone) {
					transform: skewX(0);
			}
	}

	&__caption {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, 20%);
			color: $color-white;
			text-transform: uppercase;
			font-size: 1.7rem;
			text-align: center;
			opacity: 0;
			transition: all .5s;
			backface-visibility: hidden;
	}

	&:hover &__caption {
			opacity: 1;
			transform: translate(-50%, -50%);
	}

	&:hover &__img {
			// transform: translateX(-4rem) scale(1);
			filter: blur(3px) brightness(80%);
	}
}




