.story {
	width: 100%;
	margin: 0 auto;
	box-shadow: 0 30px 60px rgba(#000, .1);
	background-color: rgba(#fff, .6);
	border-radius: 3px;
	padding: 60px;
	padding-left: 90px;
	font-size: 16px;
	transform: skewX(-12deg);

	@include respond(tab-port) {
			width: 100%;
			padding: 40px;
			padding-left: 70px;
	}

	@include respond(phone) {
			transform: skewX(0);
	}

	&__shape {
			width: 150px;
			height: 150px;
			float: left;
			transform: translateX(-30px) skewX(12deg);
			position: relative;
			overflow: hidden;
			border-radius: 50%;

			@supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
					-webkit-clip-path: circle(50% at 50% 50%);
					clip-path: circle(50% at 50% 50%);
					-webkit-shape-outside: circle(50% at 50% 50%);
					shape-outside: circle(50% at 50% 50%);
					border-radius: none;
			}

			@include respond(phone) {
					transform: translateX(-30px) skewX(0);
			}
	}

	&__img {
			height: 100%;
			transform: translateX(-40px) scale(1.4);
			backface-visibility: hidden;
			transition: all .5s;
	}

	&__text {
			transform: skewX(12deg);

			@include respond(phone) {
					transform: skewX(0);
			}
	}

	&__caption {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, 20%);
			color: #fff;
			text-transform: uppercase;
			font-size: 17px;
			text-align: center;
			opacity: 0;
			transition: all .5s;
			backface-visibility: hidden;
	}

	&:hover &__caption {
			opacity: 1;
			transform: translate(-50%, -50%);
	}

	&:hover &__img {
			transform: translateX(-40px) scale(1);
			filter: blur(3px) brightness(80%);
	}
}